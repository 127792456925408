import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { vasthuUrl } from "./Api";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function FreeUsed({ usedFreeModal, walletBalance }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userId = window.sessionStorage.getItem("userId");
  const authname = window.sessionStorage.getItem("authname");
  console.log("usedFreeModal", usedFreeModal);
  //Free Service Used Modal
  const [freeUsedModal, setFreeUsedModal] = useState(false);

  const openFreeUsedModal = () => {
    setFreeUsedModal(true);
  };

  const closeFreeUsedModal = () => {
    setFreeUsedModal(false);
    try {
      vasthuUrl
        .put(`/vasthu/user/updateFreeCallStatus/${userId}`)
        .then((res) => {
          if (walletBalance > 0) {
          } else {
            navigate("/walletoffer");
          }
        });
    } catch (e) {
      console.log("e", e);
    }
    window.sessionStorage.removeItem("pendingAction");
    window.sessionStorage.removeItem("pendingAction1");
  };

  useEffect(() => {
    if (usedFreeModal > 0) {
      openFreeUsedModal();
    }
    console.log("usedFreeModal", usedFreeModal);
  }, [usedFreeModal]);
  return (
    <div>
      <Modal
        show={freeUsedModal}
        backdrop="static"
        keyboard={false}
        onHide={closeFreeUsedModal}
      >
        <Modal.Header className="all_background" closeButton>
          <Modal.Title>{t("USR.RECH.ALT")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {t("USER.DEAR")} {authname} {t("USR.FREE.MSG.CONT")}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="all_btn1"
            onClick={closeFreeUsedModal}
            style={{ float: "right" }}
          >
            {t("USR.BOK.UR.PLN.PRCED")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default FreeUsed;
