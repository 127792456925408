import React, { useState, useEffect, useRef } from "react";
import DatePicker from "react-date-picker";
import "react-datepicker/dist/react-datepicker.css";
import { DataGrid } from "@mui/x-data-grid";
import { vasthuUrl } from "../../component/Api";
import { useTranslation } from "react-i18next";
import { Accordion, Button, Col, Form, Row } from "react-bootstrap";
import { Grid } from "@mui/material";
import NewBootCard from "../../component/NewBootCard";
import dayjs from "dayjs";
import * as XLSX from "xlsx/dist/xlsx.full.min.js";

function AdminConsultantChatHistory() {
  const token = window.sessionStorage.getItem("token");
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [clientData, setClientData] = useState([]);
  const [searchField, setSearchField] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [totalDuration, setTotalDuration] = useState("");
  const today = new Date();
  console.log("clientData", clientData);

  useEffect(() => {
    try {
      vasthuUrl
        .get(`/initiateChat/getallchatHistoryofconsultant`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          const dataWithId = res.data.map((item, index) => ({
            ...item,
            id: item.orderId || index,
          }));
          // Calculate total duration and total amount charged
          const totalDuration = res.data.reduce(
            (sum, item) => sum + item.totalDuration,
            0
          );
          const totalAmountCharged = res.data.reduce(
            (sum, item) => sum + item.totalAmountCharged,
            0
          );
          setClientData(dataWithId);
          setTotalAmount(totalAmountCharged);
          setTotalDuration(totalDuration);
        });
    } catch (e) {
      console.log("setAllOffer", e);
    }
  }, []);

  useEffect(() => {
    if (startDate && endDate) {
      const formattedStartDate = dayjs(startDate).format("YYYY-MM-DD");
      const formattedEndDate = dayjs(endDate).format("YYYY-MM-DD");

      vasthuUrl
        .get("/initiateChat/getallchatHistoryofconsultantBydate", {
          params: {
            fromDate: formattedStartDate,
            toDate: formattedEndDate,
            // consultantName: selectedConsultant,
          },
        })
        .then((res) => {
          if (res.data.length == 0) {
            setClientData("");
            setTotalAmount("0");
            setTotalDuration("0");
          }
          const dataWithId = res.data.map((item, index) => ({
            ...item,
            id: item.orderId || index,
          }));
          // Calculate total duration and total amount charged
          const totalDuration = res.data.reduce(
            (sum, item) => sum + item.totalDuration,
            0
          );
          const totalAmountCharged = res.data.reduce(
            (sum, item) => sum + item.totalAmountCharged,
            0
          );
          setClientData(dataWithId);
          setTotalAmount(totalAmountCharged);
          setTotalDuration(totalDuration);
        })
        .catch((error) => {
          console.error("Error fetching filtered data:", error);
        });
    }
  }, [startDate, endDate]);

  const columns = [
    { field: "chat_from", headerName: "User", width: 130 },

    {
      field: "duration",
      headerName: "Chat Duration",
      width: 130,
    },
    { field: "amount_charged", headerName: "Amount Charged", width: 130 },
    { field: "con_rate", headerName: "Consultant Rate", width: 130 },
    { field: "chat_type", headerName: "Chat Type", width: 130 },
    {
      field: "proposed_chat_duration",
      headerName: "Total Duration",
      width: 130,
    },
    { field: "chat_date", headerName: "Chat Date", width: 130 },
    { field: "chat_start_time", headerName: "Chat Start Time", width: 130 },
    {
      field: "connect_start_time",
      headerName: "Connect Start Time",
      width: 130,
    },
    { field: "connect_end_time", headerName: "Connect End Time", width: 130 },
    { field: "meeting_code", headerName: "Meeting Code", width: 130 },
    { field: "status", headerName: "Status", width: 100 },
  ];

  const ConsultantChatList =
    clientData &&
    clientData.filter((person) => {
      return person.chatTo.toLowerCase().includes(searchField.toLowerCase());
    });

  useEffect(() => {
    const totalDuration =
      ConsultantChatList &&
      ConsultantChatList.reduce((sum, item) => sum + item.totalDuration, 0);
    const totalAmount =
      ConsultantChatList &&
      ConsultantChatList.reduce(
        (sum, item) => sum + item.totalAmountCharged,
        0
      );
    setTotalDuration(totalDuration);
    setTotalAmount(totalAmount);
  }, [totalDuration, totalAmount, ConsultantChatList]);

  let serialNumber = 1;
  
    const downloadExcel = async () => {
      const formattedData = ConsultantChatList.flatMap((consultant) =>
        consultant.AllChatDetails.map((chat) => ({
          "S.No": serialNumber++,
          "Consultant Name": consultant.chatTo,
          "Customer Name": chat.chat_from,
          Duration: chat.duration,
          Date: dayjs(chat.chat_date).format("DD-MM-YYYY"),
          "Amount Charged": chat.amount_charged,
          "Consultant Rate": chat.con_rate,
          Status: chat.status,
          "Chat Type": chat.chat_type,
        }))
      );
  
      const worksheet = XLSX.utils.json_to_sheet(formattedData);
  
      worksheet["!cols"] = [
        { wch: 5 }, // S.No
        { wch: 20 }, // Consultant Name
        { wch: 20 }, // Customer Name
        { wch: 10 }, // Duration
        { wch: 12 }, // Date
        { wch: 15 }, // Amount Charged
        { wch: 15 }, // Consultant Rate
        { wch: 16 }, // Status
        { wch: 10 }, // Call Type
      ];
  
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  
      XLSX.writeFile(workbook, "ChatSheet.xlsx");
    };

  return (
    <>
      <Row className="mt-2 consul_chat_his" style={{ alignItems: "flex-end" }}>
        <Col sm={12} md={4} className="mt-2">
          <input
            type="text"
            className="form-control form-input form_feild"
            placeholder={t("CM.CNSL.SER.PL")}
            onChange={(e) => setSearchField(e.target.value)}
          />
        </Col>
        <Col sm={12} md={4} className="mt-2">
          <label>
            Start Date:
            <DatePicker
              className="form_feild"
              value={startDate}
              format="dd-MM-yy"
              onChange={(date) => setStartDate(date)}
              placeholderText="Select start date"
              style={{ height: "35px", borderRadius: "7px" }}
              maxDate={endDate ? endDate : today}
            />
          </label>
        </Col>
        <Col sm={12} md={4} className="mt-2">
          <label>
            End Date:
            <DatePicker
              className="form_feild"
              value={endDate}
              format="dd-MM-yy"
              onChange={(date) => setEndDate(date)}
              placeholderText="Select end date"
              minDate={startDate}
              maxDate={today}
            />
          </label>
        </Col>
      </Row>

      {ConsultantChatList.length > 0 ? (
        <div>
          <center>
            <u>
              <h2 className="mt-2">Consultant Chat History</h2>
            </u>
          </center>
          <div className="d-flex justify-content-around">
            {startDate && endDate ? (
              <h3 className="mt-2">
                {dayjs(startDate).format("DD-MM-YYYY")}&nbsp;to&nbsp;
                {dayjs(endDate).format("DD-MM-YYYY")}
              </h3>
            ) : (
              // <h3 className="mt-2">Date</h3>
              <h3 className="mt-2">OverAll List</h3>
            )}
            <u>
              <h3 className="mt-2">Total Earnings: {totalAmount}</h3>
            </u>
            <u>
              <h3 className="mt-2">Total Durations: {totalDuration}</h3>
            </u>
          </div>

          <div style={{ textAlign: "end", marginRight: "10px" }}>
            <Button className="all_btn1" onClick={downloadExcel}>
              Download Excel
            </Button>
          </div>

          {ConsultantChatList.map((i, j) => (
            <Accordion
              className="chat_accordian mt-2"
              // defaultActiveKey="0"
              key={j}
            >
              <Accordion.Item
                className="accordian_style chat_accordian"
                eventKey={j.toString()}
              >
                <Accordion.Header>
                  <Row style={{ width: "inherit" }}>
                    <Col sm={12} md={4}>
                      <h5>Consultant: {i.chatTo}</h5>
                    </Col>
                    <Col sm={12} md={4}>
                      <h5>Duration: {i.totalDuration}</h5>
                    </Col>
                    <Col sm={12} md={4}>
                      <h5>Amount: ₹{i.totalAmountCharged}</h5>
                    </Col>
                  </Row>
                </Accordion.Header>
                <Accordion.Body className="chat_acc_body">
                  <div>
                    <center className="datagrid mt-3">
                      <Grid container className="deskTable datagrid">
                        <DataGrid
                          className="all_border text-center me-3 data_grid"
                          rows={i.AllChatDetails}
                          columns={columns}
                          initialState={{
                            pagination: {
                              paginationModel: { page: 0, pageSize: 10 },
                            },
                          }}
                          pageSizeOptions={[5, 10]}
                          getRowId={(row) => row.id}
                        />
                      </Grid>
                    </center>
                    <div className="mobileCard justify-content-center">
                      {i.AllChatDetails.map((item, index) => (
                        <NewBootCard key={index}>
                          <h6>User: {item.chat_from}</h6>
                          <h6>Duration: {item.duration}</h6>
                          <h6>Amount Charged: {item.amount_charged}</h6>
                          <h6>Consultant Rate: {item.con_rate}</h6>
                          <h6>Chat Type: {item.chat_type}</h6>
                          <h6>
                            Proposed Duration: {item.proposed_chat_duration}
                          </h6>
                          <h6>Chat Date: {item.chat_date}</h6>
                          <h6>Chat Start Time: {item.chat_start_time}</h6>
                          <h6>Connect Start Time: {item.connect_start_time}</h6>
                          <h6>Connect End Time: {item.connect_end_time}</h6>
                          <h6>Meeting Code: {item.meeting_code}</h6>
                          <h6>Status: {item.status}</h6>
                        </NewBootCard>
                      ))}
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          ))}
        </div>
      ) : (
        <center>
          <h2 className="mt-2">No Record Found!</h2>
        </center>
      )}
    </>
  );
}

export default AdminConsultantChatHistory;
